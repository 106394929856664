// Normalize Styles
@import 'node_modules/normalize.css/normalize';
// @import 'node_modules/foundation-sites/scss/foundation';
@import 'node_modules/fullpage.js/dist/jquery.fullpage.scss';
@import 'node_modules/font-awesome/scss/font-awesome.scss';

@import '_colors.scss';
@import '_mixins.scss';

// Import Modules
@import '../_modules/link/link';

// Box sizing partial styles:
// Apply a natural box layout model to all elements
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.yeogurt-info {
  text-align: center;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.version {
  font-size: 14px;
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.logo {
  margin-top: 15px;
}

// Styling on warning for users on IE7 or below
.browsehappy {
  text-align: center;
  margin: 0;
  background: #fff8e2;
  color: #000;
  padding: 0.5em 0;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
  font-family: 'Josefin Slab', serif;
  #mask {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: url('../images/bg1.jpg');
    background-position: center;
    background-size: cover;
  }

  strong {
    color: $mandarin;
  }
  a {
    color: white !important;
    text-decoration: none;
    &:hover {
      color: $mandarin !important;
    }
  }
  .modal {
    display: none;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: rgba(41, 40, 39, 0.9);
    @include centerer;
    .content {
      max-height: 90%;
      max-width: 90%;
      text-align: center;
      padding: 10px 30px;
      background: rgb(150, 150, 150);
      background: rgba(150, 150, 150, 0.6);
      border-radius: 4px;
      -moz-border-radius: 4px;
      -webkit-border-radius: 4px;
      border:1px solid #fff;
      
      @include centerer;
      @include smaller {
        width: 98%; 
      }

      ul {
        color: whitesmoke;
        list-style: none;
        padding-left: 0;
        li {
          margin-bottom: 5px;
        }
      }
      h3 {
        font-size: 1.5em;
        font-weight: bold;
        color: rgb(240, 130, 95);
      }
      .close-modal {
        display: inline;
        position: absolute;
        right: -10px;
        top: -10px;
        background-color: gray;
        border-radius: 20px;
        -moz-border-radius: 20px;
        -webkit-border-radius: 20px;
        border:1px solid #fff;
        width: 20px;
        height: 20px;
        font-size: 13px;
        margin: 0;
      }
    }
  }
  .close-modal {
    display: none;
  }  
}

.fixed-header {
  position: fixed;
  top: 50px;
  height: 100px;
  width: 100%;
  padding: 0 30px;
  z-index: 100;

  @include smaller {
    top: 0px; 
  }

  .logo {
    float: left;
    width: 20%;
    img {
      @include centerer(false, true);
      height: 130px;
      margin-left: 25px;
    }

    @include smaller { 
      width: 100%;
      img {
        margin: 0;
        height: 60px;
        @include centerer;
      }
    }
  }
  .nav {
    float: right;
    width: 60%;
    height: 100%;
    background-color: red;

    @include smaller { 
      display: none;
    }
  }
}

.main-container {
  width: 100%;
  height: 100%;
}

.section {
  text-align: center;
  height: 100%;
  width: 100%;

  background-color: rgba(62, 61, 59, 0.7);
  -webkit-transition:background 1s;
  -moz-transition:background 1s;
  -o-transition:background 1s;
  transition:background 1s;

  .content {
    max-width: 750px;
    margin: auto;
    padding-left: 50px;
    padding-right: 50px;
  }

  &.cover-section {
    color: white;
    font-size: 1.2em;
    text-align: center;
    @include smaller { 
      font-size: 0.9em;
      .content {
        padding: 25px;   
      }
    }
  }

  &.services-section {
    padding-top: 50px;
    @include smaller {
      padding-top: 0px;
    } 
    
    .content {
      max-width: 95%;
      height: 60%;
      padding-top: 30px;
      .left-content {
        display: inline-block;
        width: 40%;
        @include smaller {
          display: inline-block;
          width: 100%;
        } 
      }
      .right-content {
        width: 60%;
        float: right;
        @include smaller {
          display: none
        } 
      }
    }
    .service {
      width: 350px;
      height: 100px;
      margin: 10px;
      display: inline-flex;
      background-position: center;
      background-size: cover;
      border-radius:4px;
      -moz-border-radius:4px;
      -webkit-border-radius:4px;
      cursor: pointer;
      
      @include smaller {
        width: 300px;
      } 

      &.service-1 {
        background-image: url('../images/tributaria.jpg');
      }
      &.service-2 {
        background-image: url('../images/estadosfinancieros.jpg');
      }
      &.service-3 {
        background-image: url('../images/paycheck.jpg');
      }
      &.service-4 {
        background-image: url('../images/gestion.jpg');
      }
      &.service-desc {
        width: 100%;
        height: 460px;
        padding-left: 30px;
        border-radius: 2px;
        -moz-border-radius: 2px;
        -webkit-border-radius: 2px;
        background-color: rgba(41, 40, 39, 0.9);
        border:1px solid #fff;
        text-align: left;
        display: none;
        &.show {
          display: block;
        }
        ul {
          color: whitesmoke;
          // list-style: none;
          // padding-left: 0;
          li {
            margin-bottom: 5px;
          }
        }
        h3 {
          font-size: 1.5em;
          font-weight: bold;
          color: rgb(240, 130, 95);
        }
      }
      
      .mask {
        position: absolute;
        width: inherit;
        height: inherit;
        border-radius: inherit;
        -moz-border-radius: inherit;
        -webkit-border-radius: inherit;
        border:1px solid #fff;
        background-color: rgba(41, 40, 39, 0.9);
      }

      .title {
        z-index: 2;
        margin: auto;
        color: $mandarin;
        font-size: 1.8em;
        font-weight: bold;
        p {
          -webkit-margin-before: 0em;
          -webkit-margin-after: 0em;
          -webkit-margin-start: 0px;
          -webkit-margin-end: 0px;
        }
      }
        
      @include smaller {
        height: 70px;
        margin: 10px;
        .title {
          font-size: 1.2em;
        }
      }
    }
  }

  &.contact-section{
    h2 {
      color: white;
      font-size: 1.9em;
      @include smaller {
        font-size: 1.3em;
        margin-bottom: 5px;
      } 
    }
    form {
      margin:0 auto;
      width: 100%;
      
      label {
        display:block;
        margin-top:20px;
        margin-left:10px;
        text-align: left;
        color: white;
        @include smaller { 
          margin-top:10px;
        }
      }

      input, textarea {
        width:100%;
        height:27px;
        background: rgba(245, 242, 237, 0.4);
        border-radius: 2px;
        -moz-border-radius: 2px;
        -webkit-border-radius: 2px;
        border:1px solid #fff;
        padding:10px;
        margin-top:3px;
        font-size:0.9em;
        color: white;
      }
      textarea {
        height:100px;
      }
      input#sendMessage {
        margin-top: 15px;
        height: 35px;
        width: 200px;
        color: white;
        &:hover {
          background: rgba(62, 61, 59, 0.7);
        }
      }
      @include smaller { 
        input {
          height:24px;
          margin-top:3px;
        }
        input#sendMessage {
          height:30px;
          padding-top: 6px;
          margin-top:0px;
        }
        label {
          display: none;
        }
      }

      ::-webkit-input-placeholder {
        color: #eee;
      }
      :-moz-placeholder {
        color: #eee;
      }
      ::-moz-placeholder {
        color: #eee;
      }
      :-ms-input-placeholder {
        color: #eee;
      }


    }
  }
}

.fixed-footer {
  position: fixed;
  bottom: 20px;
  width: 100%;
  height: 60px;
  
  #scroll {
    a span {
      position: absolute;
      top: 0;
      right: 40px;
      width: 46px;
      height: 46px;
      margin-left: -23px;
      border: 1px solid #fff;
      border-radius: 100%;
      box-sizing: border-box;
      @include smaller { 
        width: 28px;
        height: 28px;
        right: 20px;
        top: 40px;
      }
      &:hover {
        border-color: $mandarin;
        &::after {
          border-color: inherit;
        }
      }

      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        content: '';
        width: 16px;
        height: 16px;
        margin: -12px 0 0 -8px;
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
        border-left: 1px solid #fff;
        border-bottom: 1px solid #fff;
        box-sizing: border-box;
        @include smaller { 
          width: 12px;
          height: 12px;
          margin: -8px 0 0 -6px;
        }
      }
    }
    &.up a span::after {
      -webkit-transform: rotate(135deg);
      -moz-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
      transform: rotate(135deg);
      margin-top: -4px;
    }
  }

  #contact-us {
    margin-top: 20px;
    margin-left: 50px;
    color: white;
    @include smaller { 
      margin: 25px 0;
      width: 300px;
      font-size: 1.4em;
      @include centerer;
      small {
        display: none;
      }
    }
  }

  #under-contruction {
    position: absolute;
    font-size: .8em;
    color: white;
    bottom: -30px;
    @include centerer(true, false);
    // @include horizontal-center;
  }

  @include smaller { 
    text-align: center;
  }
}



